@use '@/styles/utils/mixins.scss' as *;

.partnersWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .sectionHeadingContainer {
    display: grid;
    gap: 3.75rem;
    grid-template-columns: repeat(3, 1fr);

    @include tab() {
      grid-template-columns: repeat(1, 1fr);
      gap: 2.5rem;
    }
  }

  .sectionLeftContent {
    grid-column: span 2;
    @include tab() {
      grid-column: span 1;
    }
  }
  .sectionRightContent {
    width: 100%;

    .sectionDescription {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 1.5rem;
        font-weight: 500;
        &:not(:first-child) {
          margin-top: 2.5rem;
        }
      }
      h1 {
        font-size: 6rem;
      }
      h2 {
        font-size: 4.5rem;
      }
      h3 {
        font-size: 3rem;
      }
      h4 {
        font-size: 2.125rem;
      }
      h5 {
        font-size: 1.5rem;
        @include mob() {
          font-size: 1rem;
        }
      }
      h6 {
        font-size: 1.5rem;
        @include mob() {
          font-size: 1rem;
        }
      }

      p {
        margin-bottom: 1rem;
      }
      ul,
      ol {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
      }
    }
  }
  .partnerLink {
    width: 10.5rem;
    height: 5.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;
    .partnerImage {
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 5.875rem;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      .partnerImage {
        filter: grayscale(100%);
      }
    }
  }
  .sliderWrapper {
    padding: 0;
    @include tab() {
      padding: 0 1.875rem;
    }
  }
  .sectionDescription {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: bold;
      font-size: 1.5rem;
      @include tab() {
        font-size: 1.25rem;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}
